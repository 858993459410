import React from "react"
import styled from "styled-components"
import { menuData } from "../../data/MenuData"
import { Link } from "gatsby"
import { FaTimes } from "react-icons/fa"
import Accordion from "../MenuAccordion"

const DropdownContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgb(16, 91, 133);
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.6s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`
const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  cursor: pointer;
  outline: none;
`
const CloseIcon = styled(FaTimes)`
  font-size: 1.6rem;
  color: #fff;
`
const DropdownWrapper = styled.div`
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow-y: auto;
`
const DropdownMenu = styled.div`
  display: grid;
  /* grid-template-columns: 1fr; */
  grid-template-rows: repeat(6, 60px);
  justify-content: center;
  text-align: center;
  margin-bottom: 3rem;
  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`
const DropdownLink = styled(Link)`
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    color: rgb(100, 111, 133);
  }
`
const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MobileMenu = ({ isOpen, toggle }) => {
  return (
    <DropdownContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <DropdownWrapper>
        <DropdownMenu>
          <div style={{ paddingLeft: "0rem" }}>
            {/* About */}
            <Accordion
              onClick={toggle}
              title1="About"
              content1='<a href="/about#story" style={{ color:"#fff" }}> Our Story </a></br><a href="/about#mission" style={{ color:"#fff" }}> Our Mission </a></br><a href="/about#values" style={{ color:"#fff" }}> Our Culture </a></br><a href="/about#team" style={{ color:"#fff" }}> Executive Team </a></br><a href="/charity"> Charity </a>'
              title2="Solutions & Services"
              content2='<strong>Application Management</strong><br/><a href="/application-management#observability" style={{ color:"#fff" }}> Observability </a><br/><a href="/application-management#application-modernisation" style={{ color:"#fff" }}> Application Modernisation </a><br/><a href="/application-management#dev-ops" style={{ color:"#fff" }}> DevOps </a><br/><br/><strong>Service Orchestration</strong><br/><a href="/service-orchestration#workflow_automation" style={{ color:"#fff" }}> Workflow Automation </a></br><a href="/service-orchestration#network_automation" style={{ color:"#fff" }}> Network Automation </a></br><a href="/service-orchestration#cloud_transformation" style={{ color:"#fff" }}> Cloud Transformation </a></br><a href="/service-orchestration#xaas_" style={{ color:"#fff" }}> XaaS </a></br><br/><strong>Full Stack Engineering</strong><br/><a href="/full-stack-engineering#api-interoperability" style={{ color:"#fff" }}> API Interoperability </a></br><a href="/application-lifecycle#cloud_native"> Cloud Native </a><br/><br/><strong>Services</strong><br/><a href="/finops" style={{color:#fff}}> FinOps </a><br/><a href="/services#strategic" style={{color:#fff}}> Strategic </a><br/><a href="/services#transformation" style={{color:#fff}}>Transformation</a><br/><a href="/services#engineering" style={{color:#fff}}>Engineering</a><br/><a href="/services#support" style={{color:#fff}}>Support</a><br/><br/><strong>Zero Trust Security</strong><br/><a href="/security-services#user" style={{ color:"#fff" }}> Identity Security </a></br><a href="/security-services#datacloud" style={{ color:"#fff" }}> Data & Cloud Security </a></br><a href="/security-services#cyber"> Cyber Security </a></br><a href="/security-services#devsecops"> DevSecOps </a>'
              title3="More  "
              content3='<a href="https://blog.metsi.com/news/"> News </a></br><a href="https://blog.metsi.com/use-cases/"> Use Cases </a></br><a href="https://www.youtube.com/channel/UC0VPmemAMa8voU6e2RDeGYQ/videos"> Videos </a><br/><a href="/press-release">Press Release</a><br/><a href="/engineering-challenge">Engineering Challenge</a><br/><a href="/job-application">Job Vacancies</a>'
            />

            {/* Services */}

            {/* Contact */}

            {/* More */}
          </div>
        </DropdownMenu>
      </DropdownWrapper>
    </DropdownContainer>
  )
}

export default MobileMenu
